import "../../style.css"

// Function to render list of books
import BookItem from "./BookItem";
import VideoGameItem from "./VideoGameItem";

const ProductItem = (props) => {
  if (props.product.type == "BookProduct") {

    return <BookItem  id={props.product.id} title={props.product.title} is_new={props.product.is_new} release_date={props.product.release_date} image={props.product.cover} image_width={props.product.cover_width} image_height={props.product.cover_height} contributors={[{ "name": props.product.author, "role": "" },
    { "name": props.product.author_2, "role": "" }]} is_checked = {props.product.is_read} key={props.product.id} />
  }
  else if (props.product.type == "MovieProduct") {
    return <BookItem id={props.product.id} title={props.product.title} is_new={props.product.is_new} is_checked = {props.product.is_read} release_date={props.product.release_date} image={props.product.image} image_width={70} image_height={80} contributors={[{ "name": props.product.author, "role": "" },
    { "name": props.product.author_2, "role": "" }]} key={props.product.id} />
  }
  else if (props.product.type == "VideoGameProduct") {
    return <VideoGameItem id={props.product.id} title={props.product.title} is_new={props.product.is_new} release_date={props.product.release_date} image={props.product.image} image_width={props.product.image_width} image_height={props.product.image_height} platform={props.product.platform} key={props.product.id} />
  }

}

/*<span className="category">{conversion[book.book_type]}</span>*/
export default ProductItem;