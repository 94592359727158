
import styles from "./catalog-filter.module.css";

import React from "react";




const CatalogFilter = ({ sortingMethod, setSortingMethod

}) => {
  let sortBy;
  if (sortingMethod == "-DATE") {
    sortBy = "date";
  }
  else if (sortingMethod == "TITLE") {
    sortBy = "titre"
  }
  else {


  }
  return (<div className={styles.catalogueFilters}>
    <div className={styles.container}>
      <div id="sorting" className="submenu">
        <input id="sorting-toggle" className="visuallyhidden" type="checkbox" />
        <label className={styles.sortingLabel} htmlFor="sorting-toggle"><span>Triés </span><span>par</span> {sortBy}<span className={styles.srOnly}> de parution</span></label>
        <ul id="sorting-popup" className={styles.popup}>
          <li><a className={sortingMethod == "-DATE" ? styles.current : ""} onClick={() => setSortingMethod("-DATE")} >Date <span>de parution</span></a>
          </li>
          <li><a className={sortingMethod == "TITLE" ? styles.current : ""} onClick={() => setSortingMethod("TITLE")}>Titre</a></li>
        </ul>
      </div>
    </div>
  </div>)
}


export default CatalogFilter;