import "../style.css"
import Header from "../components/Header";
import Footer from "../components/Footer";
import OwnProfile from "../components/OwnProfile";

export default function OwnProfilePage() {
  return (
    <>
      <Header />
      
        <OwnProfile baseUrl="" />
      
      <Footer />
    </>
  );
}

