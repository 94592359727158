
import styles from "./book-reviews.module.css";

const ProductReviews = (props) => {
  //<li><a href="#" className="tag new">Nouveauté</a></li><li><a href="#" className="tag category">Album</a></li><li><a href="#" className="tag age">Dès 2 ans</a></li><li><a href="#" className="tag">Eveil</a></li><li><a href="#" className="tag">Animaux</a></li><li><a href="#" className="tag">Nature</a></li>
  // 

  if (props.reviews.length > 1) {
    return (
      <div className={styles.reviews}>

        {props.reviews.map((review, i) => {

          return (<blockquote key={i}>
            <p>
              {review.quote}
            </p>

            <cite>{review.author}</cite>
          </blockquote>);
        })}


      </div>
    )
  }
  else {
    return (<></>);
  }
}

export default ProductReviews;