import styles from "./pagination.module.css";
import React from "react";

const Pagination = ({
  pageLimit,
  totalPages,
  activePage,
  setPage
}) => {
  const dotsfirst = `${styles.dots} ${styles.first}`;
  const dotslast = `${styles.dots} ${styles.last}`;
  const arrowactive = `${styles.arrow} ${styles.active}`;
  const arrow = `${styles.arrow}`;

  var pages = [];

  let low = Math.max(activePage - Math.floor(pageLimit / 2), 1);
  let high = Math.min(activePage + Math.floor(pageLimit / 2), totalPages);
  if (activePage + Math.floor(pageLimit / 2) > totalPages) {
    low = Math.max(activePage - Math.floor(pageLimit / 2) - (activePage + Math.floor(pageLimit / 2) - totalPages), 1);
  } else if (activePage - Math.floor(pageLimit / 2) < 1) {
    high = Math.min(activePage + Math.floor(pageLimit / 2) - (activePage - Math.floor(pageLimit / 2) - 1), totalPages);
  }

  if (low > 2) {
    pages.push(<li key="first"><a onClick={() => setPage(1)}>1</a></li>);
    pages.push(<li key="dotsfirst" className={dotsfirst}></li>);
    for (let i = low; i <= high; i++) {
      pages.push(<li key={i}><a aria-current={activePage === i ? "page" : undefined} onClick={() => setPage(i)}>{i}</a></li>);
    }
  } else {
    if (low !== 1) {
      pages.push(<li key="first"><a onClick={() => setPage(1)}>1</a></li>);
    }
    for (let i = low; i <= high; i++) {
      pages.push(<li key={i}><a aria-current={activePage === i ? "page" : undefined} onClick={() => setPage(i)}>{i}</a></li>);
    }
  }
  if (high <= totalPages - 2) {
    pages.push(<li key="dotslast" className={dotslast}></li>);
    pages.push(<li key="last"><a onClick={() => setPage(totalPages)}>{totalPages}</a></li>);
  } else if (high !== totalPages) {
    pages.push(<li key="last"><a onClick={() => setPage(totalPages)}>{totalPages}</a></li>);
  }

  if (totalPages !== 1 && totalPages) {
    return (
      <nav className={styles.pagination}>
        <ul>
          <li key="prev"><a className={activePage > 1 ? arrowactive : arrow} onClick={() => setPage(activePage - 1)}><svg viewBox="0 0 100 100"><path d="M26.81 50.026l20.68-37.783C49.252 9.025 52.795 7 56.664 7h6.146L39.334 50.026 62.81 93h-6.198c-3.872 0-7.416-2.027-9.177-5.247L26.81 50.026z"></path></svg></a></li>
          {pages}
          <li key="next"><a className={activePage < totalPages ? arrowactive : arrow} onClick={() => setPage(activePage + 1)} ><svg viewBox="0 0 100 100"><path d="M26.81 50.026l20.68-37.783C49.252 9.025 52.795 7 56.664 7h6.146L39.334 50.026 62.81 93h-6.198c-3.872 0-7.416-2.027-9.177-5.247L26.81 50.026z" transform="translate(100, 100) rotate(180) "></path></svg></a></li>
        </ul>
      </nav>
    );
  } else {
    return null;
  }
};

export default Pagination;