
import styles from "./product-availability.module.css";
import { useEffect } from 'react';
import {
    getElementsOfProduct,
    clearElements
} from '../../gett.js';
import { connect } from 'react-redux';
import Mailto from "../Mailto.js";


const BookAvailability = ({ elements, getElementsOfProduct, product }) => {
    useEffect(() => {
        getElementsOfProduct(product.id);
        return () => {
            clearElements();
        };

    }, [getElementsOfProduct, product.id]);
    let available = false;
    if (elements.results) {
        elements.results.forEach(element => {
            if (element.quantity_available > 0) {
                available = true;
            }
        });
    }
    if (elements.loading) {
        return (<div className={styles.bookElements}>
            <label className={styles.bookElementsLabel} htmlFor={styles.bookElementsToggle}
                status="ondemand"><span>Chargement...</span></label>
        </div>)
    }
    const email = "ziggy.vergne@alstomgroup.com";
    const subject = "Demande réservation " + product.title;
    const body = "Bonjour, \nJe souhaiterais réserver le produit suivant : " + product.title + ". \nJe souhaite le réserver du [INSERER DATE DÉBUT] au [INSÉRER DATE FIN]. \nMerci de me confirmer la disponibilité. \nCordialement,";
    return (
        <Mailto body={body} email={email} subject={subject}>
            <div className={styles.bookElements}>
                {available ? <label className={styles.bookElementsLabel} htmlFor={styles.bookElementsToggle}
                    status="available"><span>Disponible</span></label> : <label className={styles.bookElementsLabel} htmlFor={styles.bookElementsToggle}
                        status="ondemand"><span>Réserver</span></label>}
            </div>
        </Mailto>

    )
}

const mapStateToProps = ({ elements }) => ({
    elements
});


export default connect(mapStateToProps, {
    getElementsOfProduct
})(BookAvailability);


