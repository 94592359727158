import ProductList from "../components/ProductList"
import "../style.css"
import { connect } from 'react-redux';
import Pagination from "../components/Pagination"
import CatalogFilter from "../components/CatalogFilter";
import CatalogCategories from "../components/CatalogCategories";
import {
  clearProducts,
  getProductsByType
} from '../gett.js';

import {
  useParams,
  useNavigate

} from "react-router-dom";

import { generatePath } from "react-router";
//import { useAuth } from "react-oidc-context";
import { useEffect, useState, useRef } from 'react';


import Header from "../components/Header";
import Footer from '../components/Footer';

function Catalog({ products, getProductsByType }) {

  const { maintypeurl, subtypeurl, isnew, page, searchword, sortingmethodurl } = useParams();
  //const auth = useAuth();
  let navigate = useNavigate();

  const [changeFilter, setChangeFilter] = useState({ isNew: isnew == "new", maintype: maintypeurl === undefined ? "Books" : maintypeurl, subtype: subtypeurl === undefined ? "ComicBooks" : subtypeurl, activePage: page === undefined ? 1 : parseInt(page), category: "all", searchWord: searchword, sortingMethod: sortingmethodurl === undefined ? "-DATE" : sortingmethodurl });

  /*
    useEffect(() => {
      setChangeFilter({ ...changeFilter, isNew: isnew == "new" });
    }, [isnew]);*/
  /*
    useEffect(() => {
      setChangeFilter({ ...changeFilter, activePage: page });
    }, [page]);*/

  const initialTypeRender = useRef(true);
  useEffect(() => {
    if (initialTypeRender.current) {
      initialTypeRender.current = false;
      setChangeFilter({ ...changeFilter, maintype: maintypeurl, subtype: subtypeurl, searchWord: searchword })
    }
    else {
      setChangeFilter({ ...changeFilter, maintype: maintypeurl, subtype: subtypeurl, activePage: 1, searchWord: "", sortingMethod: "-DATE" })
    }
  }, [maintypeurl, subtypeurl]);

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      let subtype = "";
      if (subtypeurl == "Novels") {
        subtype = "NOV";
      }
      else {
        subtype = "CMB";
      }
      let maintype = ""
      if (maintypeurl == "Books") {
        maintype = "bookproducts"
      }
      else if (maintypeurl == "VideoGames") {
        maintype = "videogameproduct"
      }
      else {
        maintype = "movieproducts"
      }
      getProductsByType(maintype, subtype, changeFilter.activePage, changeFilter.searchWord, changeFilter.isNew, changeFilter.sortingMethod);

      navigate(generatePath("/:maintypeurl/:subtypeurl?/:isnew?/:category?/:sortingmethodurl?/:page?/:searchword?", { maintypeurl: changeFilter.maintype, subtypeurl: changeFilter.subtype, isnew: changeFilter.isNew ? "new" : "all", sortingmethodurl: changeFilter.sortingMethod, page: changeFilter.activePage, category: "all", searchword: changeFilter.searchWord }))
    }
  }, [changeFilter])

  function switchToNew() {
    setChangeFilter({ ...changeFilter, activePage: 1, isNew: true });
  }
  function switchToAll() {
    setChangeFilter({ ...changeFilter, activePage: 1, isNew: false });
  }
  function changePage(page) {
    setChangeFilter({ ...changeFilter, activePage: page });
  }
  function changeSearch(word) {
    setChangeFilter({ ...changeFilter, activePage: 1, searchWord: word })
  }
  function changeSortingMethod(sortingMethod) {
    setChangeFilter({ ...changeFilter, activePage: 1, sortingMethod: sortingMethod })
  }
  console.log("prod");
  console.log(products);
  return (
    <>      <Header />
      <main id="index">
        <section id="catalogue" className={(products && products.data && products.data.results.length === 0) ? "no-result" : ""}>
          <header id="catalogue-header">
            <div id="search">
              <input id="search-toggle" className="visuallyhidden" type="checkbox" />
              <label id="search-label" htmlFor="search-toggle"></label>
              <form role="search" method="GET" action="#" onSubmit={(e) => { e.preventDefault() }}>
                <input type="text" id="product-search" placeholder="Rechercher un titre, auteur…" name="q" value={changeFilter.searchWord} onChange={e => { changeSearch(e.target.value) }} /><button type="submit">OK</button>
                <label id="product-search-label" className="visuallyhidden" htmlFor="product-search">Rechercher un titre, un auteur, un EAN</label>
              </form>
            </div>
            <CatalogCategories isNew={changeFilter.isNew} typeproduct={maintypeurl} subtypeproduct={subtypeurl} switchToAll={switchToAll} switchToNew={switchToNew} />
            <CatalogFilter sortingMethod={changeFilter.sortingMethod} setSortingMethod={changeSortingMethod} />

          </header>
          <ProductList baseUrl="" />
          <Pagination

            pageLimit={5}
            activePage={changeFilter.activePage}
            totalPages={products && products.data ? products.data.total_pages : 0}
            pagesToShow={5}
            setPage={changePage}
          />
        </section>
      </main>
      <Footer />
    </>

  );
}



const mapStateToProps = ({ products }) => ({ products });

export default connect(mapStateToProps, {
  getProductsByType,
  clearProducts
})(Catalog);