
import BookAvailability from "./BookAvailability";
import MovieAvailability from "./MovieAvailability"

const ProductAvailability = (props) => {

  if (props.product.type == "BookProduct" || props.product.type == "VideoGameProduct") {
    return <BookAvailability product={props.product} />
  }
  else if (props.product.type == "MovieProduct") {
    return <MovieAvailability product={props.product} />
  }

}


export default ProductAvailability;


