import styles from "./product-details.module.css";
const BookDetails = (props) => {
    //{props.product.year}
    const currentDate = new Date(props.product.year)
    const languageNames = new Intl.DisplayNames(['fr'], { type: 'language' });
    return (
        <ul className={styles.productdetails}>
            {props.product.year ? <li><span>Parution</span><span>:</span> <span> {currentDate.getFullYear()}</span></li> : ""}
            {props.product.book_language ? <li><span>Langue</span><span>:</span> <span className={styles.language}>{languageNames.of(props.product.book_language)}</span></li> : ""}
            {props.product.nb_pages ? <li><span>Pages</span><span>:</span> <span>{props.product.nb_pages} pages</span></li> : ""}
            {props.product.publisher ? <li><span>Éditeur</span><span>:</span> <span>{props.product.publisher}</span></li> : ""}
            <li><span>EAN</span><span>:</span> <span>{props.product.ean}</span></li>
        </ul>

    )
}

export default BookDetails;