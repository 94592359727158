import Profile from "../components/Profile"
import "../style.css"
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function ProfilePage() {
  return (
    <>
      <Header />
      
        <Profile baseUrl="" />
      
      <Footer />
    </>
  );
}

