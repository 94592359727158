import { combineReducers } from 'redux';
import bookReducer from './bookReducer';
import booksReducer from './booksReducer';
import loginReducer from "./loginReducer";
import elementsReducer from "./elementsReducer";


export default combineReducers({
  book2: bookReducer,
  books: booksReducer,/* Change to products*/
  products: booksReducer,
  elements: elementsReducer,
  login: loginReducer
});
