import React, { useEffect, useState, useMemo } from "react";
import styles from "./product-reviews.module.css";
import { getReviewsOfUser } from '../../gett.js';
import { connect } from 'react-redux';
import Pagination from "../Pagination";
import { Link } from "react-router-dom";  // Import Link for declarative navigation

const ProductReviewsOfUser = ({ reviews, getReviewsOfUser, user }) => {
  // Remove the `useParams` hook since we no longer want to use URL parameters
  const [activePage, setActivePage] = useState(1); // State to store the active page

  useEffect(() => {
    if (user.id) {
      getReviewsOfUser(user.id, activePage);
    }
  }, [getReviewsOfUser, user.id, activePage]);

  const changePage = (newPage) => {
    setActivePage(newPage); // Update the page in the local state
  };

  if (reviews.loading) return <p>Chargement...</p>;
  if (!reviews.results?.length) return <p>Aucun avis pour le moment.</p>;

  return (
    <div>
      <div className={styles.reviewGrid}>
        {reviews.results.map((review, index) => (
          <div key={index} className={styles.reviewCard}>
            <h3 className={styles.reviewTitle}>{review.product_details.title}</h3>
            <Link to={`/product/${review.product_details.id}`}>
              <img
                src={review.product_details.type === "BookProduct" ? review.product_details.cover : review.product_details.image}
                className={styles.reviewImage}
              />
            </Link>
            <div className={styles.starRating}>
              {Array.from({ length: 5 }).map((_, i) => (
                <span key={i} className={i < review.rating ? styles.starFilled : styles.starEmpty}>★</span>
              ))}
            </div>
            <p className={styles.reviewComment}>{review.comment}</p>
          </div>
        ))}
      </div>
      <Pagination
        pageLimit={5}
        activePage={activePage}
        totalPages={reviews?.total_pages || 0}
        pagesToShow={5}
        setPage={changePage} // Pass changePage function to update the page in state
      />
    </div>
  );
};

const mapStateToProps = ({ reviews }) => ({
  reviews
});

export default connect(mapStateToProps, { getReviewsOfUser })(ProductReviewsOfUser);
