
import styles from "./videosample.module.css";

import ReactPlayer from 'react-player'
import React from "react";




const VideoSample = ({
  url
}) => {
  if (url && url !== "") {
    // return (<></>);
    return (
      <div className={styles.wrapper}>

        <ReactPlayer
          className={styles.reactplayer}
          width='100%'
          height='100%'

          url={url}
          controls
          config={{
            youtube: {
              playerVars: { showinfo: 1 }
            }
          }
          }
        />
      </div>);
  }
  else {
    return (<></>)
  }
}




export default VideoSample;