import styles from "./book-samples.module.css";
import Flickity from "react-flickity-component";

const VideoGameImageSamples = (props) => {
    const flickityOptions = {
        wrapAround: true,
        lazyLoad: true,
    }
    return (

        props.samples && !(props.samples.length === 0) &&
        <Flickity className={styles.bookGallery} options={flickityOptions} reloadOnUpdate={true}
        >
            {props.samples && props.samples.sort(function (a, b) {
                return a.order - b.order
            }).map(sample => (
                <img src={sample.image} key={sample.order} />
            ))}
        </Flickity>

    )
}
export default VideoGameImageSamples;