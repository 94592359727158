import "../style.css";
import {
  clearOwnProfile,
  getOwnProfile,
} from '../gett.js';
import { connect } from 'react-redux';
import { useAuth } from "react-oidc-context";
import { useEffect, useState } from 'react';
import ProductReviewsOfUser from './ProductReviewsOfUser';
import  ProductBorrowsOfOwnUser from './ProductBorrowsOfOwnUser';
import ProductBorrowsHistoryOfOwnUser from "./ProductBorrowsHistoryOfOwnUser/ProductBorrowsHistoryOfOwnUser.jsx";

const OwnProfile = ({ ownProfile, getOwnProfile, clearOwnProfile }) => {
  const auth = useAuth();

  // State for the active tab
  const [activeTab, setActiveTab] = useState('borrows'); // Default tab is 'profile'

  useEffect(() => {
    if (auth.isAuthenticated) {
      getOwnProfile();
    }
    return () => {
      clearOwnProfile();
    };
  }, [auth, clearOwnProfile, getOwnProfile, auth.isAuthenticated]);

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the clicked tab as active
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <div
        style={{
          width: "128px",
          height: "128px",
          borderRadius: "50%",
          overflow: "hidden",
          border: "4px solid #ccc",
          margin: "0 auto",
        }}
      >
        <img
          src={ownProfile.userprofile ? (ownProfile.userprofile.avatar ? ownProfile.userprofile.avatar : "/default-avatar.jpg") : "/default-avatar.jpg"}
          alt={ownProfile.first_name + " " + ownProfile.last_name}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <h2 style={{ marginTop: "10px", fontSize: "20px", fontWeight: "bold" }}>
        {ownProfile.first_name + " " + ownProfile.last_name}
      </h2>

      {/* Tab Navigation */}
      <div style={{ marginTop: "20px", display: "flex", justifyContent: "center", flexWrap: "wrap", gap: "10px" }}>
  {[
    { id: 'borrows', label: 'Emprunts' },
    { id: 'borrowshistory', label: 'Historique' },
    { id: 'reviews', label: 'Commentaires' }
  ].map(tab => (
    <button
      key={tab.id}
      onClick={() => handleTabClick(tab.id)}
      style={{
        padding: "10px 5vw", // Responsive padding
        minWidth: "120px", // Ensures a minimum button width
        border: "none",
        cursor: "pointer",
        backgroundColor: activeTab === tab.id ? '#007bff' : '#f1f1f1',
        color: activeTab === tab.id ? 'white' : 'black',
        borderRadius: "5px",
        transition: "background-color 0.3s, transform 0.2s",
        fontSize: "1rem", // Adjusts with screen size
      }}
    >
      {tab.label}
    </button>
  ))}
</div>


      {/* Tab Content */}
      <div style={{ marginTop: "20px" }}>
        {activeTab === 'borrows' && (
          <div style={{ marginTop: "10px", padding: "10px", border: "1px solid #ddd", borderRadius: "5px" }}>
            {/* You can add more profile information here */}
            <ProductBorrowsOfOwnUser />
          </div>
        )}

        {activeTab === 'reviews' && (
          <div style={{ marginTop: "10px", padding: "10px", border: "1px solid #ddd", borderRadius: "5px" }}>
            <ProductReviewsOfUser user={ownProfile} />
          </div>
        )}
        {activeTab === 'borrowshistory' && (
          <div style={{ marginTop: "10px", padding: "10px", border: "1px solid #ddd", borderRadius: "5px" }}>
            {/* You can add more profile information here */}
            <ProductBorrowsHistoryOfOwnUser />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ ownProfile }) => ({
  ownProfile,
});

export default connect(mapStateToProps, {
  getOwnProfile,
  clearOwnProfile
})(OwnProfile);
