
import styles from "./product-resume.module.css";
import ReactHtmlParser from 'react-html-parser';
const BookResume = (props) => {
  return (
    <div className={styles.resume}>

      <div style={{ whiteSpace: "pre-wrap" }}>{ReactHtmlParser(props.product.summary)}</div>

    </div>
  )
}

export default BookResume;