import BookImageSamples from "./BookImageSamples"
import VideoGameImageSamples from "./VideoGameImageSamples"


//product_gallery


const ProductImageSamples = (props) => {
  if (props.product.type === "BookProduct") {
    return <BookImageSamples samples={props.product.samples} />
  }
  else if (props.product.type === "VideoGameProduct") {
    return <VideoGameImageSamples samples={props.product.image_samples} />
  }

}


export default ProductImageSamples;