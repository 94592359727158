import { Link } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";

const BookItem = ({ id, title, is_new, release_date, image, image_width, image_height, contributors, is_checked }) => {
  const currentDate = new Date(release_date)
  const paddingBottom = image_width !== 0 ? (image_height * 100 / image_width).toString() + "%" : "100%";

  return (
    <article className={is_new ? "slide book new" : "slide book"}>
      
      <Link to={{ pathname: `/product/${id}` }} className="cover loaded" style={{ paddingBottom: paddingBottom, backgroundColor: "#0057A1" }}>
      
        <img className="lazy entered loaded" src={image} /*data-src="#"*/ alt={title} data-ll-status="loaded" />
        {is_checked ? <CiCircleCheck className="checkmark" />: "" }
      </Link>
      
      <div className="details">

        <Link to={{ pathname: `/product/${id}` }}><h4 className="title">{title}</h4></Link>
        {contributors.map((credit,index) => {
          if (credit.name && credit.name !== "") {
            return <a key={index}>{credit.name}</a>
          }
          return <a key={index}></a>;
        }

        )}
        {release_date ? <span className="date">Paru en {currentDate.getFullYear()}</span> : ""}
      </div>
    </article>
  )
};
export default BookItem;