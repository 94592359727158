
import styles from "./product-availability.module.css";
import { useEffect } from 'react';
import {
    getElementsOfProduct,
    clearElements
} from '../../gett.js';
import { connect } from 'react-redux';


const MovieAvailability = ({ elements, getElementsOfProduct, product }) => {
    useEffect(() => {
        getElementsOfProduct(product.id);
        return () => {
            clearElements();
        };
    }, [getElementsOfProduct, product.id]);
    const conversion = { "BLU": "Bluray", "DVD": "DVD" }
    let format_avail = {}
    //let format_owned = {}
    if (elements.results) {
        elements.results.forEach(element => {
            format_avail[element.format_disk] = (format_avail[element.format_disk] ? format_avail[element.format_disk] : 0) + element.quantity_available;
            //format_owned[element.format_disk] = (format_owned[element.format_disk] ? format_avail[element.format_disk] : 0) + element.quantity_owned;
        });
    }
    if (elements.loading) {
        return <></>
    }
    return (
        <div className={styles.bookElements}>
            {
                Object.entries(format_avail).map(([key, value]) => {
                    if (value == 0) {
                        return (<label key={key} className={styles.bookElementsLabel} htmlFor={styles.bookElementsToggle}
                            status="ondemand"><span>{conversion[key]} - Sur réservation</span></label>);
                    }
                    else {
                        return (<label key={key} className={styles.bookElementsLabel} htmlFor={styles.bookElementsToggle}
                            status="available"><span>{conversion[key]} - Disponible</span></label>);
                    }

                })
            }

        </div>

    )
}

const mapStateToProps = ({ elements }) => ({
    elements
});


export default connect(mapStateToProps, {
    getElementsOfProduct
})(MovieAvailability);