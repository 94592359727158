
import "../style.css"
import {
  clearProfile,
  getProfile,
} from '../gett.js';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

import { useAuth } from "react-oidc-context";
import { useEffect,useState } from 'react';
import ProductReviewsOfUser from './ProductReviewsOfUser';
import "../flickity.css";



const Profile = ({ profile, getProfile, clearProfile }) => {

  let { id } = useParams();

  const auth = useAuth();
  useEffect(() => {
    if (auth.isAuthenticated) {
      getProfile(id);
    }
    return () => {
      clearProfile();
    };
  }, [auth, clearProfile, getProfile, id, auth.isAuthenticated]);

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <div
        style={{
          width: "128px",
          height: "128px",
          borderRadius: "50%",
          overflow: "hidden",
          border: "4px solid #ccc",
          margin: "0 auto",
        }}
      >
        <img
          src={profile.userprofile?(profile.userprofile.avatar?profile.userprofile.avatar:"/default-avatar.jpg"):"/default-avatar.jpg"}
          alt={profile.first_name + " "+ profile.last_name}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <h2 style={{ marginTop: "10px", fontSize: "20px", fontWeight: "bold" }}>{profile.first_name + " "+ profile.last_name}</h2>
      
      <div style={{ marginTop: "20px" }}>        
        <div style={{ marginTop: "10px", padding: "10px", border: "1px solid #ddd", borderRadius: "5px" }}>
        <ProductReviewsOfUser user={profile} />
        </div>
      </div>
    </div>
  );
}



const mapStateToProps = ({ profile }) => ({
  profile
});



export default connect(mapStateToProps, {
  getProfile,
  clearProfile
})(Profile);

