import * as TYPES from '../types';



var own_user_borrowings_history_reducer = (state = { loading: true, exiting: false }, action) => {
  switch (action.type) {
    case TYPES.FETCH_OWN_USER_BORROWINGS_HISTORY:
      return { ...state, ...action.payload };
    case TYPES.FETCH_OWN_USER_BORROWINGS_HISTORY_LOADING:
      return { ...state, loading: true };
    case TYPES.FETCH_OWN_USER_BORROWINGS_HISTORY_CLEAN:
      return { loading: false, exiting: false };
    case TYPES.FETCH_OWN_USER_BORROWINGS_HISTORY_FINISHED:
      return { ...state, loading: false };
    default:
      return state;
  }
};
export default own_user_borrowings_history_reducer;