import * as TYPES from "./types"
import axios from 'axios';


import { User } from "oidc-client-ts"
import { from, of } from "rxjs";
import { switchMap } from "rxjs/operators";

export const getProductsByType = (maintype, subtype, activePage, searchWord, isNew, sortingMethod) => async dispatch => {
  try {
    dispatch({ type: TYPES.FETCH_BOOKS_LOADING });
    if (searchWord === undefined) {
      searchWord = ""
    }

    const request$ = from(
      axios.get("https://mindkeepr.kekfactory.fr/api/v1/" + maintype + "/?page=" + activePage + "&searchterm=" + searchWord + "&booktype=" + subtype + "&new=" + isNew + "&ordering=" + sortingMethod/*+"&"+Math.random()*12544,*/,
        {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        }
      )
    );

    request$.pipe(
      switchMap(resp => {
        const res = { data: { results: resp.data.results, totalcount: resp.data.count, total_pages: resp.data.total_pages, active_page: resp.data.page } };

        dispatch({
          type: TYPES.FETCH_BOOKS_SEARCH,
          payload: res,
        });
        dispatch({ type: TYPES.FETCH_BOOKS_FINISHED });
        return of(res);
      })
    ).subscribe();

  } catch (err) {
    console.log("err");
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
    // history.push(process.env.PUBLIC_URL + '/error');
  }
};

function getUser() {
  const oidcStorage = sessionStorage.getItem(`oidc.user:https://login.kekfactory.fr/auth/realms/cse:mindkeepr`)

  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}
function getUserToken() {
  return getUser()?.access_token;
}

export const getElementsOfProduct = id => async dispatch => {
  try {

    dispatch({ type: TYPES.FETCH_ELEMENTS_OF_PRODUCT_LOADING });

    axios.get("https://mindkeepr.kekfactory.fr/api/v1/elements?product_id=" + id + "&" + Math.random() * 12544
      , {
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
      }
    ).then((resp) => {
      dispatch({
        type: TYPES.FETCH_ELEMENTS_OF_PRODUCT,
        payload: resp.data,
      });
      console.log("getting elements of product");
      dispatch({ type: TYPES.FETCH_ELEMENTS_OF_PRODUCT_FINISHED });
    }
    );

  } catch (err) {
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
    //  history.push(process.env.PUBLIC_URL + '/error');
  }
}
export const getBook = id => async dispatch => {

  try {

    dispatch({ type: TYPES.FETCH_BOOK_LOADING });

    axios.get("https://mindkeepr.kekfactory.fr/api/v1/products/" + id + "?" + Math.random() * 12544,
      {
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
      }
    ).then((resp) => {
      dispatch({
        type: TYPES.FETCH_BOOK,
        payload: resp.data,
      });
      console.log("getting book");
      dispatch({ type: TYPES.FETCH_BOOK_FINISHED });
    }
    );

  } catch (err) {
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
    //  history.push(process.env.PUBLIC_URL + '/error');
  }
};

// Set loading to true for next render
export const clearBook = () => {
  console.log("Clear book")
  return {
    type: TYPES.FETCH_BOOK_CLEAN,
  };
};

// Set loading to true for next render
export const clearElements = () => {
  console.log("Clear elements")
  return {
    type: TYPES.FETCH_ELEMENTS_OF_PRODUCT_CLEAN,
  };
};

// Set loading to true for next render
export const clearProducts = () => {
  console.log("Clear books")
  return {
    type: TYPES.FETCH_BOOKS_CLEAN,
  };
};