import { useState } from "react";
import "./ProductReviewButton.css";
import { connect } from "react-redux";
import { getProductReview } from "../../gett";

import ProductReviewModal from "./ProductReviewModal";

const ProductReviewButton = ({ getProductReview, productReviewId, productId, alreadyCommented: initialAlreadyCommented, onReviewSubmitted }) => {
  const [open, setOpen] = useState(false);
  const [localReview, setLocalReview] = useState(null);
  const [hasCommented, setHasCommented] = useState(initialAlreadyCommented); // Local state for button message
  const [currentProductReviewId, setCurrentProductReviewId] = useState(productReviewId);
  if (!productId) return null;

  const handleOpen = async () => {
    if (currentProductReviewId) {
      const review = await getProductReview(currentProductReviewId);
      console.log(review);
      setLocalReview(review);
    } else {
      setLocalReview({ rating: 0, comment: "" });
    }
    setOpen(true);
  };

  return (
    <>
      <button className="review-button" onClick={handleOpen}>
        {hasCommented ? "Déjà commenté" : "Écrire un commentaire"}
      </button>
      <ProductReviewModal
        productId={productId}
        ratingPreset={localReview?.rating}
        commentPreset={localReview?.comment}
        isOpen={open}
        onClose={() => {
          setLocalReview(null);
          setOpen(false);
        }}
        onReviewSubmitted={(newReview) => {
          setHasCommented(true);
          setCurrentProductReviewId(newReview.id);
          console.log("Review submitted");
          if (onReviewSubmitted) 
          {
            onReviewSubmitted(); // Notify parent to refresh reviews
          }
        }} // Callback when a review is submitted
      />
    </>
  );
};

export default connect(null, { getProductReview })(ProductReviewButton);
