import * as TYPES from '../types';


var books_reducer = (state = { loading: false, exiting:false }, action) => {
  switch (action.type) {
    //case TYPES.FETCH_BOOKS_GENRE:
    //case TYPES.FETCH_BOOKS_DISCOVER:
    case TYPES.FETCH_BOOKS_SEARCH:
      return { ...state, ...action.payload };
    case TYPES.FETCH_BOOKS_LOADING:
      return { ...state, loading: true,exiting:false  };
      case TYPES.FETCH_BOOKS_CLEAN:
        return { ...state, loading: false,exiting:false  };
    case TYPES.FETCH_BOOKS_FINISHED:
      return { ...state, loading: false,exiting:false  };
    default:
      return state;
  }
};
export default books_reducer;