// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".book-reviews_reviews__vDGm9 {\n    margin-top: 1em\n}\n\n.book-reviews_reviews__vDGm9 blockquote {\n    margin: 0\n}\n\n.book-reviews_reviews__vDGm9 blockquote p {\n    margin: 0;\n    font-style: italic;\n    font-weight: 700;\n    display: inline\n}\n\n.book-reviews_reviews__vDGm9 blockquote p:first-of-type:before {\n    content: \"« \"\n}\n\n.book-reviews_reviews__vDGm9 blockquote p:last-of-type:after {\n    content: \" » \"\n}\n\n.book-reviews_reviews__vDGm9 blockquote cite {\n    font-style: normal\n}\n\n.book-reviews_reviews__vDGm9 {\n    margin-bottom: 2.5em\n}\n\n@media only screen and (min-width:64em) {\n\n    .book-reviews_reviews__vDGm9 {\n        max-width: 52rem\n    }\n}\n\n@media only screen and (min-width:64em) {\n    .book-reviews_reviews__vDGm9 {\n        font-size: 1.125em\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/ProductReviews/book-reviews.module.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,SAAS;IACT,kBAAkB;IAClB,gBAAgB;IAChB;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;;IAEI;QACI;IACJ;AACJ;;AAEA;IACI;QACI;IACJ;AACJ","sourcesContent":[".reviews {\n    margin-top: 1em\n}\n\n.reviews blockquote {\n    margin: 0\n}\n\n.reviews blockquote p {\n    margin: 0;\n    font-style: italic;\n    font-weight: 700;\n    display: inline\n}\n\n.reviews blockquote p:first-of-type:before {\n    content: \"« \"\n}\n\n.reviews blockquote p:last-of-type:after {\n    content: \" » \"\n}\n\n.reviews blockquote cite {\n    font-style: normal\n}\n\n.reviews {\n    margin-bottom: 2.5em\n}\n\n@media only screen and (min-width:64em) {\n\n    .reviews {\n        max-width: 52rem\n    }\n}\n\n@media only screen and (min-width:64em) {\n    .reviews {\n        font-size: 1.125em\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reviews": "book-reviews_reviews__vDGm9"
};
export default ___CSS_LOADER_EXPORT___;
