
import styles from "./header.module.css";
import { NavLink } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { connect } from 'react-redux';
import { useEffect } from "react";
import { getOwnProfile, clearOwnProfile } from '../../gett.js';
const Header = ({ownProfile,getOwnProfile,clearOwnProfile}) => {
  const classes = `${styles.siteHeader} product-header`
  /* <NavLink to="/Movies" className={({ isActive }) => isActive ? "active" : ''}>Films</NavLink>*/
  const auth = useAuth();
  useEffect(() => {
    if (auth.isAuthenticated) {
      getOwnProfile();
    }
    return () => {
      clearOwnProfile();
    };
  }, [auth, clearOwnProfile, getOwnProfile, auth.isAuthenticated]);
  return (
    <header className={classes}>
      <div className={styles.container}>
        <nav id="menu">
          <div className="buttons">

            <NavLink to="/Books/ComicBooks" className={({ isActive }) => isActive ? "active" : ''}>BD</NavLink>
            <NavLink to="/Books/Novels" className={({ isActive }) => isActive ? "active" : ''}>Romans</NavLink>
            <NavLink to="/VideoGames" className={({ isActive }) => isActive ? "active" : ''}>Jeux Vidéos</NavLink>

          </div>

        </nav>
        <div id="pros" className="submenu">
          <input id="pros-toggle" className="visuallyhidden" type="checkbox" />

          {!!auth.isAuthenticated && (
            <label
              type="button"
              className="noselect"
              htmlFor="pros-toggle"
            //     Logout ?
            >
              {auth.user.profile.name}

            </label>
          )}


          <div id="pros-popup" className="popup">
          <a className="button" href={"/profile"}>Mon profil</a>
            <span className="button"
              onClick={() => void auth.removeUser()}
            >Se déconnecter</span>
          </div>
        </div>
      </div>
    </header>

  );
};


const mapStateToProps = ({ ownProfile }) => ({
  ownProfile
});



export default connect(mapStateToProps, {
  getOwnProfile,
  clearOwnProfile
})(Header);