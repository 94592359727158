import "../../style.css"
import { Link } from "react-router-dom";
// Function to render list of books
const BookItem = ({ id, title, is_new, release_date, image, image_width, image_height, contributors }) => {
  const currentDate = new Date(release_date)
  const conversion = { "CMB": "BD", "NOV": "Roman" };
  // new to className to add Nouveauté to listing
  const paddingBottom = image_width !== 0 ? (image_height * 100 / image_width).toString() + "%" : "100%";

  return (
    <article className={is_new ? "slide book new" : "slide book"}>
      <Link to={{ pathname: `/book/${id}` }} className="cover loaded" style={{ paddingBottom: paddingBottom, backgroundColor: "#0057A1" }}>
        <img className="lazy entered loaded" src={image} /*data-src="#"*/ alt={title} data-ll-status="loaded" />
      </Link>
      <div className="details">

        <Link to={{ pathname: `/book/${id}` }}><h4 className="title">{title}</h4></Link>
        {contributors.map(credit => {
          if (credit.name && credit.name !== "") {
            return <a>{credit.name}</a>
          }
          return <></>;
        }

        )}
        {release_date ? <span className="date">Paru en {currentDate.getFullYear()}</span> : ""}
      </div>
    </article>
  )
};

const VideoGameItem = ({ id, title, is_new, release_date, image, image_width, image_height, platform }) => {
  const currentDate = new Date(release_date)
  const conversion = { "CMB": "BD", "NOV": "Roman" };
  // new to className to add Nouveauté to listing
  const paddingBottom = image_width !== 0 ? (image_height * 100 / image_width).toString() + "%" : "100%";

  return (
    <article className={is_new ? "slide book new" : "slide book"}>
      <Link to={{ pathname: `/book/${id}` }} className="cover loaded" style={{ paddingBottom: paddingBottom, backgroundColor: "#0057A1" }}>
        <img className="lazy entered loaded" src={image} /*data-src="#"*/ alt={title} data-ll-status="loaded" />
      </Link>
      <div className="details">

        <Link to={{ pathname: `/book/${id}` }}><h4 className="title">{title}</h4></Link>
        <a>{platform}</a>

        {release_date ? <span className="date">Paru en {currentDate.getFullYear()}</span> : ""}
      </div>
    </article>
  )
};
const conv_platform = {
  WII: "Wii",
  WIU: "Wii U",
  PS4: "PS4",
  PS5: "PS5",
  XBO: "Xbox One",
  SWI: "Switch"
};
const ProductItem = (props) => {
  if (props.product.type == "BookProduct") {

    return <BookItem id={props.product.id} title={props.product.title} is_new={props.product.is_new} release_date={props.product.release_date} image={props.product.cover} image_width={props.product.cover_width} image_height={props.product.cover_height} contributors={[{ "name": props.product.author, "role": "" },
    { "name": props.product.author_2, "role": "" }]} key={props.product.id} />
  }
  else if (props.product.type == "MovieProduct") {
    return <BookItem id={props.product.id} title={props.product.title} is_new={props.product.is_new} release_date={props.product.release_date} image={props.product.image} image_width={70} image_height={80} contributors={[{ "name": props.product.author, "role": "" },
    { "name": props.product.author_2, "role": "" }]} key={props.product.id} />
  }
  else if (props.product.type == "VideoGameProduct") {
    return <VideoGameItem id={props.product.id} title={props.product.title} is_new={props.product.is_new} release_date={props.product.release_date} image={props.product.image} image_width={props.product.image_width} image_height={props.product.image_height} platform={conv_platform[props.product.platform]} key={props.product.id} />
  }

}

/*<span className="category">{conversion[book.book_type]}</span>*/
export default ProductItem;