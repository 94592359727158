import { combineReducers } from 'redux';
import productReducer from './productReducer';
import productsReducer from './productsReducer';
import loginReducer from "./loginReducer";
import elementsReducer from "./elementsReducer";
import reviewsReducer from './reviewsReducer';
import profileReducer from './profileReducer';
import ownProfileReducer from './ownProfile';
import ownUserBorrowingsReducer from './ownUserBorrowingsReducer';
import ownUserBorrowingsHistoryReducer from './ownUserBorrowingsHistoryReducer';
export default combineReducers({
  product: productReducer,
  products: productsReducer,
  elements: elementsReducer,
  login: loginReducer,
  reviews: reviewsReducer,
  profile: profileReducer,
  ownProfile: ownProfileReducer,
  ownUserBorrowings: ownUserBorrowingsReducer,
  ownUserBorrowingsHistory: ownUserBorrowingsHistoryReducer,
});
