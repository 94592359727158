// TYPES AS CONST SO ITS EASIER TO SPOT ERRORS

// Get basic cfg obj from API and Genres for the sidebar
export const GET_CONFIG = 'GET_CONFIG';
export const GET_GENRES = 'GET_GENRES';

// Set the selected menu on sidebar is valid
export const SELECTED_MENU = 'SELECTED_MENU';
export const REMOVE_SELECTED_MENU = 'REMOVE_SELECTED_MENU';

// Fetching
export const FETCH_BOOK = 'FETCH_BOOK';

export const FETCH_BOOKS_DISCOVER = 'FETCH_BOOKS_DISCOVER';
export const FETCH_BOOKS_SEARCH = 'FETCH_BOOKS_SEARCH';
export const FETCH_BOOKS_RECOMMENDED = 'FETCH_BOOKS_RECOMMENDED';
export const FETCH_BOOKS_FINISHED = "FETCH_BOOKS_FINISHED";
export const FETCH_BOOKS_LOADING = "FETCH_BOOKS_LOADING";
export const FETCH_BOOKS_CLEAN = "FETCH_BOOKS_CLEAN";
// LOADINGS
// geral loading
export const SET_LOADING = 'SET_LOADING';
export const REMOVE_LOADING = 'REMOVE_LOADING';

// movies loading
export const FETCH_BOOK_LOADING = 'FETCH_BOOK_LOADING';
export const FETCH_BOOK_CLEAN = 'FETCH_BOOK_CLEAN';
export const FETCH_BOOK_FINISHED = 'FETCH_BOOK_FINISHED';

export const FETCH_ELEMENTS_OF_PRODUCT_FINISHED = "FETCH_ELEMENTS_OF_PRODUCT_FINISHED";
export const FETCH_ELEMENTS_OF_PRODUCT_LOADING = "FETCH_ELEMENTS_OF_PRODUCT_LOADING"
export const FETCH_ELEMENTS_OF_PRODUCT = "FETCH_ELEMENTS_OF_PRODUCT";
export const FETCH_ELEMENTS_OF_PRODUCT_CLEAN = "FETCH_ELEMENTS_OF_PRODUCT_CLEAN";


export const LOGIN_OK = "LOGIN_OK"
// single movie loading
export const FETCH_MOVIE_LOADING = 'FETCH_MOVIE_LOADING';
export const FETCH_MOVIE_FINISHED = 'FETCH_MOVIE_FINISHED';

// fetching person loading
export const FETCH_PERSON_LOADING = 'FETCH_PERSON_LOADING';
export const FETCH_PERSON_FINISHED = 'FETCH_PERSON_FINISHED';

// Fetching recommendations
export const FETCH_RECOMMENDATIONS_LOADING = 'FETCH_RECOMMENDATIONS_LOADING';
export const FETCH_RECOMMENDATIONS_FINISHED = 'FETCH_RECOMMENDATIONS_FINISHED';

//Fetch movie by actor
export const FETCH_MOVIESPERSON_LOADING = 'FETCH_MOVIESPERSON_LOADING';
export const FETCH_MOVIESPERSON_FINISHED = 'FETCH_MOVIESPERSON_FINISHED';

//Errors
export const INSERT_ERROR = 'INSERT_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
