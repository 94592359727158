
import "../style.css"
import {
  clearProduct,
  getProduct,
} from '../gett.js';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

import { useAuth } from "react-oidc-context";
import { useEffect,useState } from 'react';

import ProductShortDescription from "./ProductShortDescription/index.js";
import ProductCover from "./ProductCover/index.js";
import Productdetails from "./ProductDetails/index.js";
import ProductResume from "./ProductResume/index.js"
import ProductImageSamples from "./ProductImageSamples/index.js"
import ProductTags from "./ProductTags/index.js"
import ProductReviews from "./ProductReviews/index.js"
import ProductCredits from "./ProductCredits/index.js";
import ProductAvailability from "./ProductAvailability/index.js";
import VideoSample from "./VideoSample/index.js";
import ProductReviewButton from "./ProductReviewButton/index.js";
import "../flickity.css";



const Product = ({ product, getProduct, clearProduct }) => {

  let { id } = useParams();
  const [refreshReviews, setRefreshReviews] = useState(false);

  const handleReviewSubmitted = () => {
    setRefreshReviews(prev => !prev); // Toggle state to trigger refresh
  };
  const auth = useAuth();
  useEffect(() => {
    if (auth.isAuthenticated) {
      getProduct(id);
    }
    return () => {
      clearProduct();
    };
  }, [auth, clearProduct, getProduct, id, auth.isAuthenticated]);
  
  //{currentDate.getFullYear()}
  // <li><a href="#" className="tag new">Nouveauté</a></li><li><a href="#" className="tag category">Album</a></li><li><a href="#" className="tag age">Dès 2 ans</a></li><li><a href="#" className="tag">Eveil</a></li><li><a href="#" className="tag">Animaux</a></li><li><a href="#" className="tag">Nature</a></li>
  return (
    <article style={product.loading ? { display: 'none' } : {}}>
      <header id="product-header" >
        <h1 className="title">{product.title}</h1>
        <ProductCredits
          credits={[{ "name": product.author, "role": "" },
          { "name": product.author_2, "role": "" }]}

        />
      </header>
      <div id="product-sticky">
        <ProductCover
          product={product}
        />
        <ProductAvailability
          product={product} />
        <Productdetails
          product={product} />
      </div>
      <div id="product-content">

        <ProductTags tags={[]} isNew={product.is_new} />

        <ProductShortDescription product={product} />

        <ProductResume product={product} />
        <ProductImageSamples product={product} />
        <VideoSample url={product.trailer_video_url} />
        <ProductReviewButton productReviewId={product.own_user_comment?.id}
        productId={product.id} 
        alreadyCommented={product.own_user_comment==null?false:true} 
        onReviewSubmitted={handleReviewSubmitted} />
        <ProductReviews product={product}
                        refresh={refreshReviews} />
        
      </div>
    </article >

  )
};

const mapStateToProps = ({ product }) => ({
  product
});



/* <div id="book-buy">
   <input id="buy-toggle" className="visuallyhidden" type="checkbox" />
   <label id="buy-label" className="noselect" htmlFor="buy-toggle"><span>Réserver</span><span className="price">19 €</span></label>
   <ul id="buy-popup" className="popup">
     <li><a href="https://www.librairiesindependantes.com/product/9782377319176" target="_blank">Près de chez moi</a></li>
     <li><a href="https://www.placedeslibraires.fr/livre/9782377319176" target="_blank">Place des libraires</a></li>
     <li><a href="https://www.leslibraires.fr/livre/9782377319176" target="_blank">Leslibraires.fr</a></li>
     <li><a href="https://www.bdfugue.com/catalogsearch/result/?q=9782377319176" target="_blank">BDfugue</a></li>
     <li><a href="https://www.mollat.com/livres/9782377319176" target="_blank">Mollat</a></li>
     <li><a href="https://www.librairie-kleber.com/#/search/9782377319176" target="_blank">Librairies Kléber</a></li>
     <li><a href="https://www.sauramps.com/livre/9782377319176" target="_blank">Sauramps</a></li>
     <li><a href="https://www.librairiedialogues.fr/livre/9782377319176" target="_blank">Librairie Dialogues</a></li>
     <li><a href="https://www.ombres-blanches.fr/search?search=%7B%22query%22%3A%229782377319176%22,%22searchId%22%3Anull%7D" target="_blank">Ombres blanches</a></li>
     <li><a href="https://www.libraires-ensemble.com/livre/9782377319176" target="_blank">Les libraires ensemble</a></li>
     <li><a href="https://www.decitre.fr/livres/9782377319176.html" target="_blank">Decitre</a></li>
     <li><a href="http://www.cultura.com/catalogsearch/result/?q=9782377319176" target="_blank">Cultura</a></li>
     <li><a href="https://www.fnac.com/SearchResult/ResultList.aspx?Search=9782377319176" target="_blank">Fnac</a></li>
     <li><a href="https://www.amazon.fr/s?k=9782377319176" target="_blank">Amazon</a></li>
   </ul>
 </div>
 <div id="book-download">
   <input id="download-toggle" className="visuallyhidden" type="checkbox" />
   <label id="download-label" className="noselect" htmlFor="download-toggle"><span>Ressources pros</span><span className="filescount">1</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path className="cls-1" d="M6.35,17a5,5,0,0,1-1.88-.36,5.16,5.16,0,0,1-1.65-1.09A5,5,0,0,1,1.36,12a5,5,0,0,1,1.46-3.5h0L9.4,2a3.57,3.57,0,0,1,5,0A3.53,3.53,0,0,1,15.2,3.2a3.58,3.58,0,0,1,.26,1.33,3.52,3.52,0,0,1-1,2.5L7.86,13.54a2.12,2.12,0,0,1-1.51.63,2,2,0,0,1-.8-.16,2,2,0,0,1-.71-.47,2.1,2.1,0,0,1-.47-.7,2.15,2.15,0,0,1-.16-.8,2.24,2.24,0,0,1,.16-.8,2.21,2.21,0,0,1,.47-.7l6.05-6a.71.71,0,0,1,1,1l-6,6a.71.71,0,1,0,1,1L13.41,6a2.11,2.11,0,0,0-3-3h0L3.83,9.54a3.52,3.52,0,0,0-1,2.5,3.52,3.52,0,0,0,1,2.51A3.54,3.54,0,0,0,5,15.32a3.44,3.44,0,0,0,1.34.26,3.4,3.4,0,0,0,1.34-.26,3.54,3.54,0,0,0,1.18-.77L15.42,8a.71.71,0,0,1,1,0,.7.7,0,0,1,0,1L9.88,15.55A5,5,0,0,1,6.35,17Z"></path></svg></label>
   <ul id="download-popup" className="popup">
     <li className="locked">
       <label htmlFor="pros-toggle">Couverture HD <span className="file-info">(2,98&nbsp;MB)</span></label>
     </li>
     <li className="pro-tip">
       <strong>Vous êtes professionnel·le du livre&nbsp;?</strong>
       <label htmlFor="pros-toggle" className="underline">Connectez-vous</label> ou <a href="https://editions-sarbacane.com/professionnels/inscription" className="underline">Créez votre compte</a> pour accéder aux ressources exclusives.
     </li>
   </ul>
 </div>*/

export default connect(mapStateToProps, {
  getProduct,
  clearProduct
})(Product);

