import * as TYPES from "./types";
import axios from "axios";
import { User } from "oidc-client-ts";
import conf from "./config.json";

/**
 * Retrieve configuration data safely.
 */
export const getConfigData = key => conf[process.env.REACT_APP_NODE_ENV]?.[key] ?? "";


let cancelToken; // Store the cancel token globally

/**
 * Retrieve the user from session storage.
 */
function getUser() {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${getConfigData("sso_url")}:${getConfigData("sso_client_id")}`
  );

  return oidcStorage ? User.fromStorageString(oidcStorage) : null;
}

/**
 * Retrieve the user token.
 */
export function getUserToken() {
  return getUser()?.access_token;
}

/**
 * Fetch products by type with optional search, pagination, and sorting.
 */
export const getProductsByType =
  (maintype, subtype, activePage, searchWord = "", isNew, sortingMethod) =>
  async dispatch => {
    try {
      dispatch({ type: TYPES.FETCH_PRODUCTS_LOADING });

      const params = new URLSearchParams({
        page: activePage,
        searchterm: searchWord,
        booktype: subtype,
        new: isNew,
        ordering: sortingMethod,
      });
      // Cancel the previous request if it exists
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }

      // Create a new cancel token
      cancelToken = axios.CancelToken.source();
      const { data } = await axios.get(`${getConfigData("api_base_url")}/${maintype}/?${params}`, {
        headers: { Authorization: `Bearer ${getUserToken()}` },
        cancelToken: cancelToken.token, // Attach the cancel token
      });

      dispatch({ type: TYPES.FETCH_PRODUCTS_SEARCH, payload: { data } });
      dispatch({ type: TYPES.FETCH_PRODUCTS_FINISHED });
    } catch (err) {
      console.error("Error fetching products:", err);
      dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
    }
  };

/**
 * Fetch elements of a product by ID.
 */
export const getElementsOfProduct = id => async dispatch => {
  try {
    dispatch({ type: TYPES.FETCH_ELEMENTS_OF_PRODUCT_LOADING });

    const { data } = await axios.get(`${getConfigData("api_base_url")}/elements?product_id=${id}&_=${new Date().getTime()}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    });

    dispatch({ type: TYPES.FETCH_ELEMENTS_OF_PRODUCT, payload: data });
    dispatch({ type: TYPES.FETCH_ELEMENTS_OF_PRODUCT_FINISHED });
  } catch (err) {
    console.error("Error fetching elements of product:", err);
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
  }
};

/**
 * Fetch a book by ID.
 */
export const getProduct = id => async dispatch => {
  try {
    dispatch({ type: TYPES.FETCH_PRODUCT_LOADING });

    const { data } = await axios.get(`${getConfigData("api_base_url")}/products/${id}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    });

    dispatch({ type: TYPES.FETCH_PRODUCT, payload: data });
    dispatch({ type: TYPES.FETCH_PRODUCT_FINISHED });
  } catch (err) {
    console.error("Error fetching book:", err);
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
  }
};

/** Fetch reviews of product */
export const getReviewsOfProduct = id => async dispatch => {
  try {
    if(!id) return;
    dispatch({ type: TYPES.FETCH_REVIEWS_LOADING });

    const { data } = await axios.get(`${getConfigData("api_base_url")}/productreviews/?product_id=${id}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    });
    dispatch({ type: TYPES.FETCH_REVIEWS, payload: data });
    dispatch({ type: TYPES.FETCH_REVIEWS_FINISHED });
  } catch (err) {
    console.error("Error fetching reviews:", err);
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
  }
};

export const getReviewsOfUser = (id, activePage) => async dispatch => {
  try {
    dispatch({ type: TYPES.FETCH_REVIEWS_LOADING });

    const { data } = await axios.get(`${getConfigData("api_base_url")}/productreviews/?user_id=${id}&page=${activePage}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    });
    dispatch({ type: TYPES.FETCH_REVIEWS, payload: data });
    dispatch({ type: TYPES.FETCH_REVIEWS_FINISHED });
  } catch (err) {
    console.error("Error fetching reviews:", err);
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
  }
}
export const getProfile = id => async dispatch => {
  try {
    dispatch({ type: TYPES.FETCH_PROFILE_LOADING });

    const { data } = await axios.get(`${getConfigData("api_base_url")}/userprofile/${id}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    });
    dispatch({ type: TYPES.FETCH_PROFILE, payload: data });
    dispatch({ type: TYPES.FETCH_PROFILE_FINISHED });
  } catch (err) {
    console.error("Error fetching profile:", err);
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
  }
}
export const getOwnProfile = () => async dispatch => {
  try {
    dispatch({ type: TYPES.FETCH_OWN_PROFILE_LOADING });

    const { data } = await axios.get(`${getConfigData("api_base_url")}/ownuserprofile`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    });
    dispatch({ type: TYPES.FETCH_OWN_PROFILE, payload: data });
    dispatch({ type: TYPES.FETCH_OWN_PROFILE_FINISHED });
  } catch (err) {
    console.error("Error fetching profile:", err);
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
  }
}

export const getOwnUserBorrowings = (activePage) => async dispatch => {
  try {
    dispatch({ type: TYPES.FETCH_OWN_USER_BORROWINGS_LOADING });

    const { data } = await axios.get(`${getConfigData("api_base_url")}/ownproductsborrowings?page=${activePage}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    });

    dispatch({ type: TYPES.FETCH_OWN_USER_BORROWINGS, payload: data });
    dispatch({ type: TYPES.FETCH_OWN_USER_BORROWINGS_FINISHED });
  } catch (err) {
    console.error("Error fetching profile:", err);
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
  }
}

export const getOwnUserBorrowingsHistory = (activePage) => async dispatch => {
  try {
    dispatch({ type: TYPES.FETCH_OWN_USER_BORROWINGS_HISTORY_LOADING });

    const { data } = await axios.get(`${getConfigData("api_base_url")}/ownproductsborrowingshistory?page=${activePage}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    });
    dispatch({ type: TYPES.FETCH_OWN_USER_BORROWINGS_HISTORY, payload: data });
    dispatch({ type: TYPES.FETCH_OWN_USER_BORROWINGS_HISTORY_FINISHED });
  } catch (err) {
    console.error("Error fetching profile:", err);
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
  }
}

export const getProductReview = (product_review_id) => async dispatch => {
  try {
    console.log("Fetching product review with ID:", product_review_id);
    const { data } = await axios.get(`${getConfigData("api_base_url")}/productreviews/${product_review_id}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    });
    return data;
  } catch (err) {
    console.error("Error fetching reviews:", err);
   }
   return null;
  
}
// Utility function to create action clearers
const createClearAction = type => () => ({ type });

export const clearProduct = createClearAction(TYPES.FETCH_PRODUCT_CLEAN);
export const clearElements = createClearAction(TYPES.FETCH_ELEMENTS_OF_PRODUCT_CLEAN);
export const clearProducts = createClearAction(TYPES.FETCH_PRODUCTS_CLEAN);
export const clearReviews = createClearAction(TYPES.FETCH_REVIEWS_CLEAN);
export const clearProfile = createClearAction(TYPES.FETCH_PROFILE_CLEAN);
export const clearOwnProfile = createClearAction(TYPES.FETCH_OWN_PROFILE_CLEAN);
export const clearOwnUserBorrowings = createClearAction(TYPES.FETCH_OWN_USER_BORROWINGS_CLEAN);
//export const clearOwnUserBorrowingsHistory = createClearAction(TYPES.FETCH_OWN_USER_BORROWINGS_HISTORY_CLEAN);