// TYPES AS CONST SO ITS EASIER TO SPOT ERRORS

// Fetching
export const FETCH_PRODUCT = 'FETCH_PRODUCT';

export const FETCH_PRODUCTS_SEARCH = 'FETCH_PRODUCTS_SEARCH';
export const FETCH_PRODUCTS_FINISHED = "FETCH_PRODUCTS_FINISHED";
export const FETCH_PRODUCTS_LOADING = "FETCH_PRODUCTS_LOADING";
export const FETCH_PRODUCTS_CLEAN = "FETCH_PRODUCTS_CLEAN";

export const FETCH_PRODUCT_LOADING = 'FETCH_PRODUCT_LOADING';
export const FETCH_PRODUCT_CLEAN = 'FETCH_PRODUCT_CLEAN';
export const FETCH_PRODUCT_FINISHED = 'FETCH_PRODUCT_FINISHED';

export const FETCH_ELEMENTS_OF_PRODUCT_FINISHED = "FETCH_ELEMENTS_OF_PRODUCT_FINISHED";
export const FETCH_ELEMENTS_OF_PRODUCT_LOADING = "FETCH_ELEMENTS_OF_PRODUCT_LOADING"
export const FETCH_ELEMENTS_OF_PRODUCT = "FETCH_ELEMENTS_OF_PRODUCT";
export const FETCH_ELEMENTS_OF_PRODUCT_CLEAN = "FETCH_ELEMENTS_OF_PRODUCT_CLEAN";

export const FETCH_REVIEWS_LOADING = "FETCH_REVIEWS_LOADING";
export const FETCH_REVIEWS_FINISHED = "FETCH_REVIEWS_FINISHED";
export const FETCH_REVIEWS = "FETCH_REVIEWS";
export const FETCH_REVIEWS_CLEAN = "FETCH_REVIEWS_CLEAN";

export const FETCH_PROFILE_LOADING = "FETCH_PROFILE_LOADING";
export const FETCH_PROFILE_FINISHED = "FETCH_PROFILE_FINISHED";
export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PROFILE_CLEAN = "FETCH_PROFILE_CLEAN";

export const FETCH_OWN_PROFILE_LOADING = "FETCH_OWN_PROFILE_LOADING";
export const FETCH_OWN_PROFILE_FINISHED = "FETCH_OWN_PROFILE_FINISHED";
export const FETCH_OWN_PROFILE = "FETCH_OWN_PROFILE";
export const FETCH_OWN_PROFILE_CLEAN = "FETCH_OWN_PROFILE_CLEAN";

export const FETCH_OWN_USER_BORROWINGS_LOADING = "FETCH_OWN_USER_BORROWINGS_LOADING";
export const FETCH_OWN_USER_BORROWINGS_FINISHED = "FETCH_OWN_USER_BORROWINGS_FINISHED";
export const FETCH_OWN_USER_BORROWINGS = "FETCH_OWN_USER_BORROWINGS";
export const FETCH_OWN_USER_BORROWINGS_CLEAN = "FETCH_OWN_USER_BORROWINGS_CLEAN";

export const FETCH_OWN_USER_BORROWINGS_HISTORY_LOADING = "FETCH_OWN_USER_BORROWINGS_HISTORY_LOADING";
export const FETCH_OWN_USER_BORROWINGS_HISTORY_FINISHED = "FETCH_OWN_USER_BORROWINGS_HISTORY_FINISHED";
export const FETCH_OWN_USER_BORROWINGS_HISTORY = "FETCH_OWN_USER_BORROWINGS_HISTORY";
export const FETCH_OWN_USER_BORROWINGS_HISTORY_CLEAN = "FETCH_OWN_USER_BORROWINGS_HISTORY_CLEAN";

export const LOGIN_OK = "LOGIN_OK"



//Errors
export const INSERT_ERROR = 'INSERT_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
