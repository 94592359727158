import ProductItem from "./ProductItem2/ProductItem"
import React from "react";

import { connect } from 'react-redux';


const ProductList = ({ books }) => {
  console.log(books.data)
  if (!books || !books.data) {
    return (<></>);
  }
  if (books.data.results.length === 0) {
    return (<div id="books" ><article><div></div><p>Aucun résultat…</p></article></div>);
  }

  if (books.data) {
    return (
      <React.Fragment>
        <div id="books" >
          {books.data.results.map(book => {
            return <ProductItem product={book} />
          }
          )}
        </div>
      </React.Fragment>
    );
  }
  else {
    return (
      <React.Fragment>
        <div id="books">
        </div>
      </React.Fragment>
    );
  }

};


const mapStateToProps = ({ books }) => ({ books });

export default connect(mapStateToProps, {})(ProductList);