import styles from "./product-details.module.css";
const MovieDetails = (props) => {
    //{props.product.year}
    const release_date = new Date(props.product.release_date)

    return (
        <ul className={styles.productdetails}>
            {props.product.release_date ? <li><span>Sortie</span><span>:</span> <span> {release_date.getFullYear()}</span></li> : ""}
            {props.product.time_length ? <li><span>Durée</span><span>:</span> <span> {props.product.time_length} minutes</span></li> : ""}
            {props.product.nationality ? <li><span>Nationalité</span><span>:</span> <span>{props.product.nationality}</span></li> : ""}

        </ul>

    )
}
/*     {props.product.budget ? <li><span>Budget</span><span>:</span> <span>{props.product.budget}</span></li> : ""}*/
export default MovieDetails;