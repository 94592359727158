import { BrowserRouter, Routes, Route } from "react-router-dom";
import Catalog from "./Catalog"

import ProductPage from "./ProductPage"
import ProfilePage from "./ProfilePage"
import OwnProfilePage from "./OwnProfilePage"
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import App from './App';


import { useAuth } from "react-oidc-context";
function Library({ products, product }) {
  const auth = useAuth();
  //console.log(auth)


  useEffect(() => {

  }, [products.loading, product.loading]);

  return (
    <div id="site" className={(products.exiting && product.loading) ? "site is-exiting to-book" : ((product.exiting && products.loading) ? "site is-exiting" : "site")} >
      <Helmet>
        <title>Book Library</title>
        <meta
          name="description"
          content="A book Library based on Mindkeepr"
        />
      </Helmet>
      <BrowserRouter>

        <Routes>

          <Route path="/" element={(!!auth.isAuthenticated) ? <Catalog /> : <App />} />
          <Route path="/Product/:id" element={(!!auth.isAuthenticated) ? <ProductPage /> : <App />} />
          <Route path="/Movie/:id" element={(!!auth.isAuthenticated) ? <ProductPage /> : <App />} />
          <Route path="/:maintypeurl/:subtypeurl?/:isnew?/:category?/:sortingmethodurl?/:page?/:searchword?" element={(!!auth.isAuthenticated) ? <Catalog /> : <App />} /> 
          <Route path="/profile/:id" element={(!!auth.isAuthenticated) ? <ProfilePage /> : <App />} />
          <Route path="/profile" element={(!!auth.isAuthenticated) ? <OwnProfilePage /> : <App />} />
         
         
        </Routes>

      </BrowserRouter>
    </div>
  )
}

const mapStateToProps = ({ products, product }) => ({ products, product });

export default connect(mapStateToProps, {})(Library);