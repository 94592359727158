// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".product-credits_credits__VHLCx a {\n    border-bottom: 1px dotted rgba(178, 190, 191, .6);\n    transition: all .1s ease\n}\n\n.product-credits_credits__VHLCx {\n    margin: .2em 0 2em\n}\n\n.product-credits_credits__VHLCx li {\n    display: inline-block;\n    line-height: 1.5rem\n}\n\n.product-credits_credits__VHLCx li:not(:last-of-type):after {\n    content: \", \"\n}\n\n.product-credits_credits__VHLCx a:hover,\n.product-credits_credits__VHLCx a:active {\n    color: #00b3cb;\n    border-bottom: 1px dotted rgb(0, 179, 203, .6)\n}\n\n\n.product-credits_credits__VHLCx span {\n    opacity: .4;\n    font-size: 0 .875em\n}\n\n\n.product-credits_credits__VHLCx span {\n    text-transform: lowercase\n}\n\n@media only screen and (min-width:64em) {\n\n    .product-credits_credits__VHLCx {\n        font-size: 1.125em\n    }\n\n}", "",{"version":3,"sources":["webpack://./src/components/ProductCredits/product-credits.module.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;IACjD;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,qBAAqB;IACrB;AACJ;;AAEA;IACI;AACJ;;AAEA;;IAEI,cAAc;IACd;AACJ;;;AAGA;IACI,WAAW;IACX;AACJ;;;AAGA;IACI;AACJ;;AAEA;;IAEI;QACI;IACJ;;AAEJ","sourcesContent":[".credits a {\n    border-bottom: 1px dotted rgba(178, 190, 191, .6);\n    transition: all .1s ease\n}\n\n.credits {\n    margin: .2em 0 2em\n}\n\n.credits li {\n    display: inline-block;\n    line-height: 1.5rem\n}\n\n.credits li:not(:last-of-type):after {\n    content: \", \"\n}\n\n.credits a:hover,\n.credits a:active {\n    color: #00b3cb;\n    border-bottom: 1px dotted rgb(0, 179, 203, .6)\n}\n\n\n.credits span {\n    opacity: .4;\n    font-size: 0 .875em\n}\n\n\n.credits span {\n    text-transform: lowercase\n}\n\n@media only screen and (min-width:64em) {\n\n    .credits {\n        font-size: 1.125em\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"credits": "product-credits_credits__VHLCx"
};
export default ___CSS_LOADER_EXPORT___;
