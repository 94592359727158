
import styles from "./header.module.css";
import { NavLink } from "react-router-dom";
import { useAuth } from "react-oidc-context";

const Header = () => {
  const auth = useAuth();
  const classes = `${styles.siteHeader} book-header`
  /* <NavLink to="/Movies" className={({ isActive }) => isActive ? "active" : ''}>Films</NavLink>*/
  return (
    <header className={classes}>
      <div className={styles.container}>
        <nav id="menu">
          <div className="buttons">

            <NavLink to="/Books/ComicBooks" className={({ isActive }) => isActive ? "active" : ''}>BD</NavLink>
            <NavLink to="/Books/Novels" className={({ isActive }) => isActive ? "active" : ''}>Romans</NavLink>
            <NavLink to="/VideoGames" className={({ isActive }) => isActive ? "active" : ''}>Jeux Vidéos</NavLink>

          </div>

        </nav>
        <div id="pros" className="submenu">
          <input id="pros-toggle" className="visuallyhidden" type="checkbox" />

          {!!auth.isAuthenticated && (
            <label
              type="button"
              className="noselect"
              htmlFor="pros-toggle"
            //     Logout ?
            >
              {auth.user.profile.name}

            </label>
          )}


          <div id="pros-popup" className="popup">

            <span className="button"
              onClick={() => void auth.removeUser()}
            >Se déconnecter</span>


          </div>
        </div>
      </div>
    </header>

  );
};


export default Header;