import Product from "../components/Product"
import "../style.css"
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function ProductPage() {
  return (
    <>
      <Header />
      <main id="book">
        <Product baseUrl="" />
      </main>
      <Footer />
    </>
  );
}

