
//import styles from "./catalog-categories.module.css";

import React from "react";


const CatalogCategories = ({ typeproduct, subtypeproduct, isNew, switchToNew, switchToAll

}) => {
  let allProductText = ""
  if (typeproduct === "Books" && subtypeproduct === "Novels") {
    allProductText = "Tous les romans"
  }
  else if (typeproduct === "Books" && subtypeproduct === "ComicBooks") {
    allProductText = "Toutes les BD"
  }
  else if (typeproduct === "VideoGames") {
    allProductText = "Tous les Jeux"
  }
  else {
    allProductText = "Tous les films"
  }

  return (<div id="catalogue-categories" className={isNew ? "new" : "all"}>
    <input id="categories-toggle" className="visuallyhidden" type="checkbox" />
    <label id="categories-label" htmlFor="categories-toggle">Nouveautés</label>
    <ul id="categories-popup" className="popup">
      <li><a className="new" onClick={() => { switchToNew() }}>Nouveautés</a></li><li><a href="#" className="all" onClick={() => { switchToAll() }}>
        {allProductText}
      </a></li>
    </ul>
  </div>)
}


export default CatalogCategories;