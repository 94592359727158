
import styles from "./footer.module.css";


const Footer = () => {
  return (<footer className={styles.siteFooter}>
    <nav className={styles.footerMenu}>
      <div className={styles.container}>
        <a href="#">Contacts</a><a href="#">Mentions légales</a><a href="#" className={styles.credits} rel="noopener">Site réalisé par M. Z</a>
      </div>
    </nav>
  </footer>);
}


export default Footer;