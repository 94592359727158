import { BrowserRouter, Routes, Route } from "react-router-dom";
import Catalog from "./Catalog"

import BookPage from "./BookPage"
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import App from './App';


import { useAuth } from "react-oidc-context";
function Library({ books, book2 }) {
  const auth = useAuth();
  console.log(auth)


  useEffect(() => {
    console.log("Loading lib");
    console.log(books.loading);
    console.log(book2.loading);
  }, [books.loading, book2.loading]);

  return (
    <div id="site" className={(books.exiting && book2.loading) ? "site is-exiting to-book" : ((book2.exiting && books.loading) ? "site is-exiting" : "site")} >
      <Helmet>
        <title>Book Library</title>
        <meta
          name="description"
          content="A book Library based on Mindkeepr"
        />
      </Helmet>
      <BrowserRouter>

        <Routes>

          <Route path="/" element={(!!auth.isAuthenticated) ? <Catalog /> : <App />} />
          <Route path="/Book/:id" element={(!!auth.isAuthenticated) ? <BookPage /> : <App />} />
          <Route path="/Movie/:id" element={(!!auth.isAuthenticated) ? <BookPage /> : <App />} />
          <Route path="/:maintypeurl/:subtypeurl?/:isnew?/:category?/:sortingmethodurl?/:page?/:searchword?" element={(!!auth.isAuthenticated) ? <Catalog /> : <App />} />
        </Routes>

      </BrowserRouter>
    </div>
  )
}


const mapStateToProps = ({ books, book2 }) => ({ books, book2 });

export default connect(mapStateToProps, {})(Library);