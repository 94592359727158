import styles from "./product-short-description.module.css"

const MovieShortDescription = (props) => {
    return (
        <div id="description">
            {props.product.catch_phrase}
        </div>
    )
}

export default MovieShortDescription;