import * as TYPES from '../types';



var elements_reducer = (state = { loading: true, exiting: false }, action) => {
  switch (action.type) {
    case TYPES.FETCH_ELEMENTS_OF_PRODUCT:
      return { ...state, ...action.payload };
    case TYPES.FETCH_ELEMENTS_OF_PRODUCT_LOADING:
      return { ...state, loading: true };
    case TYPES.FETCH_ELEMENTS_OF_PRODUCT_CLEAN:
      return { loading: false, exiting: false };
    case TYPES.FETCH_ELEMENTS_OF_PRODUCT_FINISHED:
      return { ...state, loading: false };
    default:
      return state;
  }
};
export default elements_reducer;