import * as TYPES from '../types';

var login_reducer = (state = { }, action) => {
  switch (action.type) {
    case TYPES.LOGIN_OK:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default login_reducer;
