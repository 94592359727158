
import './App.css';


import { useAuth } from "react-oidc-context";

function App() {

  const auth = useAuth();


  switch (auth.activeNavigator) {
    case "signinSilent":
        return <div>Signing you in...</div>;
    case "signoutRedirect":
        return <div>Signing you out...</div>;
    default :
        /* Do nothing*/
}

if (auth.isLoading) {
    return <div>Loading...</div>;
}

if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
}


  return (
    <div className="App">
      <header className="App-header">
        <h1>mindreadr</h1>

        <label
          id="loginbutton"
          type="button"
          className="noselect"
          htmlFor="pros-toggle"
          onClick={() => void auth.signinRedirect()}
        >
          Login
        </label>
      </header>

    </div>
  );
}

export default App;
