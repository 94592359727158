import React, { Fragment } from 'react';

import ReactDOM from "react-dom/client";
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store';


import { Provider } from 'react-redux';
import { AuthProvider } from "react-oidc-context";

import Library from "./containers/Library"
import conf from './config.json';

export const configData = key => conf[process.env.REACT_APP_NODE_ENV][key]
const root = ReactDOM.createRoot(document.getElementById('site-wrapper'));

const onSigninCallback = (_user) => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  )
}
const oidcConfig = {
  authority: configData("sso_url"),
  client_id: configData("sso_client_id"),
  redirect_uri: configData("url_base"),
  onSigninCallback: onSigninCallback
}


root.render(

  //
  //<React.StrictMode>

  <AuthProvider {...oidcConfig}>

    <Provider store={store}>
      <Fragment>
        <Library />

      </Fragment>
    </Provider>
  </AuthProvider>
  //</React.StrictMode>



);
/*  */
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
