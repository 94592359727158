import ProductItem from "./ProductItem/ProductItem"
import React from "react";

import { connect } from 'react-redux';


const ProductList = ({ products }) => {
  if (!products || !products.data) {
    return (<></>);
  }
  if (products.data.results.length === 0) {
    return (<div id="books" ><article><div></div><p>Aucun résultat…</p></article></div>);
  }

  if (products.data) {
    return (
      <React.Fragment>
        <div id="books" >
          {products.data.results.map(book => {
            return <ProductItem key={book.id} product={book} />
          }
          )}
        </div>
      </React.Fragment>
    );
  }
  else {
    return (
      <React.Fragment>
        <div id="books">
        </div>
      </React.Fragment>
    );
  }

};


const mapStateToProps = ({ products }) => ({ products });

export default connect(mapStateToProps, {})(ProductList);