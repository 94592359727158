import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./product-reviews.module.css";
import { getOwnUserBorrowings } from "../../gett.js";
import Pagination from "../Pagination/index.js";
import ProductReviewButton from "../ProductReviewButton/index.js";

const ProductBorrowsOfOwnUser = ({ ownUserBorrowings, getOwnUserBorrowings }) => {
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    getOwnUserBorrowings(activePage);
  }, [getOwnUserBorrowings, activePage]);

  // Memoize results to avoid unnecessary re-renders
  const borrowings = useMemo(() => ownUserBorrowings?.results || [], [ownUserBorrowings]);
  const changePage = (newPage) => setActivePage(newPage);
  console.log(borrowings);
  // Loading state
  if (ownUserBorrowings.loading) return <p className={styles.loading}>Chargement...</p>;

  // Empty state
  if (borrowings.length === 0) return <p className={styles.noReviews}>Aucun emprunt pour le moment.</p>;
  
  return (
    <div>
      <div className={styles.reviewGrid}>
        {borrowings.map((borrowing) => (
          <div key={borrowing.id} className={styles.reviewCard}>
            <h3 className={styles.reviewTitle}>{borrowing.product.title===""?borrowing.element.name:borrowing.product.title}</h3>
            {borrowing.product.title!=="" && (
            <Link to={`/product/${borrowing.product.id}`}>
              <img
                src={borrowing.product.type === "BookProduct" ? borrowing.product.cover : borrowing.product.image}
                alt={borrowing.product.title}
                className={styles.reviewImage}
              />
            </Link>)}
            <p className={styles.reviewComment}>
              Emprunté le&nbsp;
              {new Date(borrowing.borrow_date_display).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })} 
              , à rendre le&nbsp;
              {new Date(borrowing.return_date_display).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })}
            </p>
            <ProductReviewButton productReviewId={borrowing.product.own_user_comment?.id} productId={borrowing.product.id} alreadyCommented={borrowing.product.own_user_comment!==null} />
          </div>
        ))}
      </div>

      <Pagination
        pageLimit={5}
        activePage={activePage}
        totalPages={ownUserBorrowings?.total_pages || 0}
        pagesToShow={5}
        setPage={changePage}
      />
    </div>
  );
};

const mapStateToProps = ({ ownUserBorrowings }) => ({
  ownUserBorrowings,
});

export default connect(mapStateToProps, { getOwnUserBorrowings })(ProductBorrowsOfOwnUser);
