import * as TYPES from '../types';


var products_reducer = (state = { loading: false, exiting:false }, action) => {
  switch (action.type) {
    case TYPES.FETCH_PRODUCTS_SEARCH:
      return { ...state, ...action.payload };
    case TYPES.FETCH_PRODUCTS_LOADING:
      return { ...state, loading: true,exiting:false  };
      case TYPES.FETCH_PRODUCTS_CLEAN:
        return { ...state, loading: false,exiting:false  };
    case TYPES.FETCH_PRODUCTS_FINISHED:
      return { ...state, loading: false,exiting:false  };
    default:
      return state;
  }
};
export default products_reducer;