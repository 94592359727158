
import styles from "./product-credits.module.css";


const ProductCredits = ({ credits }) => {



  return (
    <ul className={styles.credits}>
      {credits.map(credit => {
        if (credit.name && credit.name !== "") {
          return <li><a>{credit.name}</a> <span>{credit.role ? (credit.role) : ""}</span></li>
        }
        return <></>;
      }

      )}


    </ul>

  )
}



export default ProductCredits;