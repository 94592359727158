import { Link } from "react-router-dom";
const conv_platform = {
    WII: "Wii",
    WIU: "Wii U",
    PS4: "PS4",
    PS5: "PS5",
    XBO: "Xbox One",
    SWI: "Switch"
  };

const VideoGameItem = ({ id, title, is_new, release_date, image, image_width, image_height, platform }) => {
    const currentDate = new Date(release_date)
    
    const paddingBottom = image_width !== 0 ? (image_height * 100 / image_width).toString() + "%" : "100%";
    const platform_conv = conv_platform[platform];
    return (
      <article className={is_new ? "slide book new" : "slide book"}>
        <Link to={{ pathname: `/product/${id}` }} className="cover loaded" style={{ paddingBottom: paddingBottom, backgroundColor: "#0057A1" }}>
          <img className="lazy entered loaded" src={image} /*data-src="#"*/ alt={title} data-ll-status="loaded" />
        </Link>
        <div className="details">
  
          <Link to={{ pathname: `/product/${id}` }}><h4 className="title">{title}</h4></Link>
          <a>{platform_conv}</a>
  
          {release_date ? <span className="date">Paru en {currentDate.getFullYear()}</span> : ""}
        </div>
      </article>
    )
  };
    export default VideoGameItem;