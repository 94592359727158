import styles from "./product-short-description.module.css"

const BookShortDescription = (props) => {
    return (
        <div id="description">
            {props.product.short_description}
        </div>
    )
}

export default BookShortDescription;