import React from "react";
import styles from "./book-reviews.module.css";
import { useEffect } from 'react';
import {
    getReviewsOfProduct,
    clearReviews
} from '../../gett.js';
import { connect } from 'react-redux';

const ProductReviews = ({ reviews,refresh, getReviewsOfProduct, product }) => {
      useEffect(() => {
        getReviewsOfProduct(product.id);
          return () => {
              clearReviews();
          };
  
      }, [getReviewsOfProduct, product.id,refresh]);
  if (!reviews.results || reviews.results.length === 0) {
    return <p className={styles.noReviews}>Aucun avis pour le moment.</p>;
  }
  
  return (
    <div className={styles.reviewsContainer}>
      {reviews.results.map((review, index) => (
        <div key={index} className={styles.reviewCard}>
        <div className={styles.reviewLeft}>
        <a href={"/profile/"+review.user.id}>
          <img 
            src={review.user.userprofile.avatar ? review.user.userprofile.avatar : "/default-avatar.jpg"} 
            alt={`${review.user.first_name} ${review.user.last_name}`} 
            className={styles.avatar} 
          />
          </a>
        </div>

        <div className={styles.reviewContent}>
          <div className={styles.reviewHeader}>
            <a href={"/profile/"+review.user.id}><span className={styles.author}>{review.user.first_name} {review.user.last_name}</span></a>
            <span className={styles.date}>{new Date(review.created_at).toLocaleDateString()}</span>
          </div>

          <div className={styles.rating}>
            {"⭐".repeat(review.rating)}{"☆".repeat(5 - review.rating)}
          </div>

          <blockquote className={styles.quote}>
            <p>{review.comment}</p>
          </blockquote>
        </div>
      </div>
      ))}
    </div>
  );
};

const mapStateToProps = ({ reviews }) => ({
  reviews
});


export default connect(mapStateToProps, {getReviewsOfProduct}) (ProductReviews);