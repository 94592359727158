
import styles from "./product-short-description.module.css";


import React from "react";

import BookShortDescription from "./BookShortDescription"
import MovieShortDescription from "./MovieShortDescription"
import VideoGameShortDescription from "./VideoGameShortDescription"

const ProductShortDescription = (
  props
) => {
  if (props.product.type == "BookProduct") {
    return <BookShortDescription product={props.product} />
  }
  else if (props.product.type == "MovieProduct") {
    return <MovieShortDescription product={props.product} />
  }
  else if (props.product.type == "VideoGameProduct") {
    return <VideoGameShortDescription product={props.product} />
  }
}




export default ProductShortDescription;