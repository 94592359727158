
import styles from "./product-availability.module.css";
import { useEffect } from 'react';
import {
    getElementsOfProduct,
    clearElements
} from '../../gett.js';
import { connect } from 'react-redux';


const BookAvailability = ({ elements, getElementsOfProduct, product }) => {
    useEffect(() => {
        getElementsOfProduct(product.id);
        return () => {
            clearElements();
        };

    }, [getElementsOfProduct, product.id]);
    let available = false;
    if (elements.results) {
        elements.results.forEach(element => {
            console.log(element);
            if (element.quantity_available > 0) {
                available = true;
            }
        });
    }
    if (elements.loading) {
        return (<div className={styles.bookElements}>
            <label className={styles.bookElementsLabel} htmlFor={styles.bookElementsToggle}
                status="ondemand"><span>Chargement...</span></label>
        </div>)
    }
    return (
        <div className={styles.bookElements}>
            {available ? <label className={styles.bookElementsLabel} htmlFor={styles.bookElementsToggle}
                status="available"><span>Disponible</span></label> : <label className={styles.bookElementsLabel} htmlFor={styles.bookElementsToggle}
                    status="ondemand"><span>Sur réservation</span></label>}


        </div>

    )
}

const mapStateToProps = ({ elements }) => ({
    elements
});


export default connect(mapStateToProps, {
    getElementsOfProduct
})(BookAvailability);


