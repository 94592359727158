
import styles from "./product-tags.module.css";

const ProductTags = (props) => {

  const classes = `${styles.tag} ${styles.new}`

  return (
    <ul className={styles.productTags}>
      {props.isNew ? <li key={0}><span className={classes}>Nouveauté</span></li> : <></>}

      {props.tags.map((tag, i) => {

        return (<li key={i + 1}>
          <span className={styles.tag}>{tag}</span>
        </li>);
      })}

    </ul>
  )
}

export default ProductTags;