import { useState,useEffect } from "react";
import axios from "axios";
import {getUserToken} from "../../gett"
import conf from "../../config";
import { createPortal } from "react-dom";
/**
 * Retrieve configuration data safely.
 */
export const getConfigData = key => conf[process.env.REACT_APP_NODE_ENV]?.[key] ?? "";

export default function ProductReviewModal({ productId, isOpen, onClose, ratingPreset, commentPreset, onReviewSubmitted }) {
    const [rating, setRating] = useState(ratingPreset);
    const [comment, setComment] = useState(commentPreset);
    const [loading, setLoading] = useState(false);
    const [hoverRating, setHoverRating] = useState(0);
    useEffect(() => {
      setRating(ratingPreset);
      setComment(commentPreset);
    }, [ratingPreset, commentPreset]);
  
    const submitReview = async () => {
      if (rating === 0) return;
      setLoading(true);
      try {
        const token = getUserToken();
        const response = await axios.post(`${getConfigData("api_base_url")}/productreviews/`, {
          product: productId,
          rating,
          comment,
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.status == 201)
        {
            onReviewSubmitted(response.data); // Notify parent that a review was posted ✅
        }
        onClose();
        setRating(0);
        setComment("");
      } catch (error) {
        console.error("Failed to submit review", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (!isOpen) return null;
  
    return createPortal(
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal" onClick={(e) => e.stopPropagation()}>
          <h2>Envoyer un commentaire</h2>
          <div className="star-rating">
            {[1, 2, 3, 4, 5].map((star) => (
                <span
                key={star}
                className={star <= (hoverRating || rating) ? "star selected" : "star"} // ✅ Uses hover OR selected rating
                onClick={() => setRating(star)}
                onMouseEnter={() => setHoverRating(star)}
                onMouseLeave={() => setHoverRating(0)}
                >
                ★
                </span>
            ))}
          </div>
          <textarea
            className="textarea"
            placeholder="Écrivez votre commentaire... (facultatif)"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <button className="submit-button" onClick={submitReview} disabled={loading}>
            {loading ? "Envoi en cours..." : "Envoyer"}
          </button>
          <button className="close-button" onClick={onClose}>Fermer</button>
        </div>
      </div>,
      document.body
    );
  }
  