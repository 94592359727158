// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".videosample_wrapper__36kcK {\n    margin-top: 2em;\n    position: relative;\n    padding-top: 46.25%;\n    max-width: 52rem;\n}\n\n.videosample_reactplayer__eev3s {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/VideoSample/videosample.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;AAChB","sourcesContent":[".wrapper {\n    margin-top: 2em;\n    position: relative;\n    padding-top: 46.25%;\n    max-width: 52rem;\n}\n\n.reactplayer {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "videosample_wrapper__36kcK",
	"reactplayer": "videosample_reactplayer__eev3s"
};
export default ___CSS_LOADER_EXPORT___;
