// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".product-tags_tag__3aWWN {\n    display: inline-block;\n    border-radius: 2em;\n    outline: 0;\n    white-space: nowrap;\n    transition: background-color .1s ease, color .1s ease, border-color .1s ease, padding .1s ease\n}\n\n.product-tags_tag__3aWWN {\n    padding: .25em .7em;\n    border: 1px solid #b2bebf;\n    background-color: #fff\n}\n\n.product-tags_new__176Iy,\n.product-tags_upcoming__DcgMe {\n    border-color: currentColor\n}\n\n.product-tags_new__176Iy {\n    color: #30af00\n}\n\n.product-tags_tag__3aWWN span {\n    margin-left: .2em;\n    opacity: .5\n}\n\n.product-tags_productTags__sdYIn li {\n    display: inline-block;\n    margin: .3em .6em .3em 0\n}\n\n@media only screen and (min-width:64em) {\n    .product-tags_productTags__sdYIn {\n        max-width: 52rem\n    }\n\n}", "",{"version":3,"sources":["webpack://./src/components/ProductTags/product-tags.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB;AACJ;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;IACzB;AACJ;;AAEA;;IAEI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,iBAAiB;IACjB;AACJ;;AAEA;IACI,qBAAqB;IACrB;AACJ;;AAEA;IACI;QACI;IACJ;;AAEJ","sourcesContent":[".tag {\n    display: inline-block;\n    border-radius: 2em;\n    outline: 0;\n    white-space: nowrap;\n    transition: background-color .1s ease, color .1s ease, border-color .1s ease, padding .1s ease\n}\n\n.tag {\n    padding: .25em .7em;\n    border: 1px solid #b2bebf;\n    background-color: #fff\n}\n\n.new,\n.upcoming {\n    border-color: currentColor\n}\n\n.new {\n    color: #30af00\n}\n\n.tag span {\n    margin-left: .2em;\n    opacity: .5\n}\n\n.productTags li {\n    display: inline-block;\n    margin: .3em .6em .3em 0\n}\n\n@media only screen and (min-width:64em) {\n    .productTags {\n        max-width: 52rem\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "product-tags_tag__3aWWN",
	"new": "product-tags_new__176Iy",
	"upcoming": "product-tags_upcoming__DcgMe",
	"productTags": "product-tags_productTags__sdYIn"
};
export default ___CSS_LOADER_EXPORT___;
