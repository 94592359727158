
import styles from "./book-cover.module.css";

const ProductCover = (props) => {

    /*style={{ paddingBottom: props.paddingBottom, backgroundColor: "#2B4F9D" }}*/
    if (props.product.type == "BookProduct") {
        return (
            <div className={styles.productCover}>
                <figure>
                    <div className="cover-container">
                        <span /*href="#"*/ className="cover loaded" download=""><img className="lazy entered loaded" src={props.product.cover} /*data-src="#"*/ alt={props.product.title} data-ll-status="loaded" /></span>
                    </div>

                </figure>
            </div>
        )
    }
    else {
        return (
            <div className={styles.productCover}>
                <figure>
                    <div className="cover-container">
                        <span /*href="#"*/ className="cover loaded" download=""><img className="lazy entered loaded" src={props.product.image} /*data-src="#"*/ alt={props.product.title} data-ll-status="loaded" /></span>
                    </div>

                </figure>
            </div>
        )
    }
}


/*             <figcaption>
                    <h2 className="title">{props.title}</h2>
                    <ul className="credits">
                        <li>{props.author}</li>
                        <li>{props.author_2}</li>
                    </ul>
                </figcaption>*/
export default ProductCover;