import * as TYPES from '../types';



var own_user_borrowings_reducer = (state = { loading: true, exiting: false }, action) => {
  switch (action.type) {
    case TYPES.FETCH_OWN_USER_BORROWINGS:
      return { ...state, ...action.payload };
    case TYPES.FETCH_OWN_USER_BORROWINGS_LOADING:
      return { ...state, loading: true };
    case TYPES.FETCH_OWN_USER_BORROWINGS_CLEAN:
      return { loading: false, exiting: false };
    case TYPES.FETCH_OWN_USER_BORROWINGS_FINISHED:
      return { ...state, loading: false };
    default:
      return state;
  }
};
export default own_user_borrowings_reducer;