import styles from "./product-details.module.css";
const VideoGameDetails = (props) => {
    //{props.product.year}
    const release_date = new Date(props.product.release_date)
    const conv_platform = {
        WII: "Wii",
        WIU: "Wii U",
        PS4: "PS4",
        PS5: "PS5",
        XBO: "Xbox One",
        SWI: "Switch"
    };

    return (
        <ul className={styles.productdetails}>
            {props.product.release_date ? <li><span>Sortie</span><span>:</span> <span> {release_date.getFullYear()}</span></li> : ""}
            {props.product.publisher ? <li><span>Éditeur</span><span>:</span> <span> {props.product.publisher}</span></li> : ""}
            {props.product.platform && conv_platform[props.product.platform] ? <li><span>Console</span><span>:</span> <span> {conv_platform[props.product.platform]}</span></li> : ""}
            {props.product.nb_player_max ? <li><span>Joueurs max</span><span>:</span> <span> {props.product.nb_player_max}</span></li> : ""}
            {props.product.age_restriction ? <li><span>PEGI</span><span>:</span> <span> {props.product.age_restriction}</span></li> : ""}

        </ul>

    )
}
/*     {props.product.budget ? <li><span>Budget</span><span>:</span> <span>{props.product.budget}</span></li> : ""}*/
export default VideoGameDetails;