import Book from "../components/Book"
import "../style.css"
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function BookPage() {
  return (
    <>
      <Header />
      <main id="book">
        <Book baseUrl="" />
      </main>
      <Footer />
    </>
  );
}

