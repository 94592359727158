
import BookDetails from "./BookDetails"
import MovieDetails from "./MovieDetails";
import VideoGameDetails from "./VideoGameDetails";

const Productdetails = (props) => {
  console.log(props.product.type);
  if (props.product.type == "BookProduct") {
    return <BookDetails product={props.product}></BookDetails>
  }
  else if (props.product.type == "MovieProduct") {
    return <MovieDetails product={props.product}></MovieDetails>
  }
  else if (props.product.type == "VideoGameProduct") {
    return <VideoGameDetails product={props.product}></VideoGameDetails>
  }
}

export default Productdetails;