

import MovieResume from "./MovieResume"
import BookResume from "./BookResume"
import VideoGameResume from "./VideoGameResume"
const ProductResume = (props) => {
  if (props.product.type === "BookProduct") {
    return <BookResume product={props.product} />
  }
  else if (props.product.type === "MovieProduct") {
    return <MovieResume product={props.product} />
  }
  else if (props.product.type === "VideoGameProduct") {
    return <VideoGameResume product={props.product} />
  }

}

export default ProductResume;